// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-accordion-mdx": () => import("./../../../../src/components/accordion.mdx" /* webpackChunkName: "component---src-components-accordion-mdx" */),
  "component---src-components-alert-mdx": () => import("./../../../../src/components/alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-components-badge-mdx": () => import("./../../../../src/components/badge.mdx" /* webpackChunkName: "component---src-components-badge-mdx" */),
  "component---src-components-box-mdx": () => import("./../../../../src/components/box.mdx" /* webpackChunkName: "component---src-components-box-mdx" */),
  "component---src-components-breadcrumb-mdx": () => import("./../../../../src/components/breadcrumb.mdx" /* webpackChunkName: "component---src-components-breadcrumb-mdx" */),
  "component---src-components-button-mdx": () => import("./../../../../src/components/button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-components-card-mdx": () => import("./../../../../src/components/card.mdx" /* webpackChunkName: "component---src-components-card-mdx" */),
  "component---src-components-dropdown-mdx": () => import("./../../../../src/components/dropdown.mdx" /* webpackChunkName: "component---src-components-dropdown-mdx" */),
  "component---src-components-loader-mdx": () => import("./../../../../src/components/loader.mdx" /* webpackChunkName: "component---src-components-loader-mdx" */),
  "component---src-components-modal-mdx": () => import("./../../../../src/components/modal.mdx" /* webpackChunkName: "component---src-components-modal-mdx" */),
  "component---src-components-pagination-mdx": () => import("./../../../../src/components/pagination.mdx" /* webpackChunkName: "component---src-components-pagination-mdx" */),
  "component---src-components-progressbar-mdx": () => import("./../../../../src/components/progressbar.mdx" /* webpackChunkName: "component---src-components-progressbar-mdx" */),
  "component---src-components-steps-mdx": () => import("./../../../../src/components/steps.mdx" /* webpackChunkName: "component---src-components-steps-mdx" */),
  "component---src-components-tabs-mdx": () => import("./../../../../src/components/tabs.mdx" /* webpackChunkName: "component---src-components-tabs-mdx" */),
  "component---src-components-tag-mdx": () => import("./../../../../src/components/tag.mdx" /* webpackChunkName: "component---src-components-tag-mdx" */),
  "component---src-components-toast-mdx": () => import("./../../../../src/components/toast.mdx" /* webpackChunkName: "component---src-components-toast-mdx" */),
  "component---src-components-tooltip-mdx": () => import("./../../../../src/components/tooltip.mdx" /* webpackChunkName: "component---src-components-tooltip-mdx" */),
  "component---src-content-dark-mode-mdx": () => import("./../../../../src/content/dark-mode.mdx" /* webpackChunkName: "component---src-content-dark-mode-mdx" */),
  "component---src-content-icons-mdx": () => import("./../../../../src/content/icons.mdx" /* webpackChunkName: "component---src-content-icons-mdx" */),
  "component---src-content-images-mdx": () => import("./../../../../src/content/images.mdx" /* webpackChunkName: "component---src-content-images-mdx" */),
  "component---src-content-table-mdx": () => import("./../../../../src/content/table.mdx" /* webpackChunkName: "component---src-content-table-mdx" */),
  "component---src-content-typography-mdx": () => import("./../../../../src/content/typography.mdx" /* webpackChunkName: "component---src-content-typography-mdx" */),
  "component---src-forms-input-mdx": () => import("./../../../../src/forms/input.mdx" /* webpackChunkName: "component---src-forms-input-mdx" */),
  "component---src-forms-range-mdx": () => import("./../../../../src/forms/range.mdx" /* webpackChunkName: "component---src-forms-range-mdx" */),
  "component---src-forms-select-mdx": () => import("./../../../../src/forms/select.mdx" /* webpackChunkName: "component---src-forms-select-mdx" */),
  "component---src-forms-selectors-mdx": () => import("./../../../../src/forms/selectors.mdx" /* webpackChunkName: "component---src-forms-selectors-mdx" */),
  "component---src-forms-textarea-mdx": () => import("./../../../../src/forms/textarea.mdx" /* webpackChunkName: "component---src-forms-textarea-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-layout-breakpoints-mdx": () => import("./../../../../src/layout/breakpoints.mdx" /* webpackChunkName: "component---src-layout-breakpoints-mdx" */),
  "component---src-layout-container-mdx": () => import("./../../../../src/layout/container.mdx" /* webpackChunkName: "component---src-layout-container-mdx" */),
  "component---src-layout-grid-mdx": () => import("./../../../../src/layout/grid.mdx" /* webpackChunkName: "component---src-layout-grid-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-styleguide-styleguide-mdx": () => import("./../../../../src/styleguide/styleguide.mdx" /* webpackChunkName: "component---src-styleguide-styleguide-mdx" */),
  "component---src-utilities-color-tokens-mdx": () => import("./../../../../src/utilities/color-tokens.mdx" /* webpackChunkName: "component---src-utilities-color-tokens-mdx" */),
  "component---src-utilities-colors-mdx": () => import("./../../../../src/utilities/colors.mdx" /* webpackChunkName: "component---src-utilities-colors-mdx" */),
  "component---src-utilities-display-mdx": () => import("./../../../../src/utilities/display.mdx" /* webpackChunkName: "component---src-utilities-display-mdx" */),
  "component---src-utilities-flex-mdx": () => import("./../../../../src/utilities/flex.mdx" /* webpackChunkName: "component---src-utilities-flex-mdx" */),
  "component---src-utilities-position-mdx": () => import("./../../../../src/utilities/position.mdx" /* webpackChunkName: "component---src-utilities-position-mdx" */),
  "component---src-utilities-rounds-mdx": () => import("./../../../../src/utilities/rounds.mdx" /* webpackChunkName: "component---src-utilities-rounds-mdx" */),
  "component---src-utilities-scrollbar-mdx": () => import("./../../../../src/utilities/scrollbar.mdx" /* webpackChunkName: "component---src-utilities-scrollbar-mdx" */),
  "component---src-utilities-shadows-mdx": () => import("./../../../../src/utilities/shadows.mdx" /* webpackChunkName: "component---src-utilities-shadows-mdx" */),
  "component---src-utilities-spacing-mdx": () => import("./../../../../src/utilities/spacing.mdx" /* webpackChunkName: "component---src-utilities-spacing-mdx" */),
  "component---src-utilities-text-mdx": () => import("./../../../../src/utilities/text.mdx" /* webpackChunkName: "component---src-utilities-text-mdx" */)
}

