module.exports = [{
      plugin: require('../../node_modules/gatsby-theme-docz/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/css/css/src/docs/.docz","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Getting Started","Content","Layout","Forms","Components","Utilities","Styleguide"],"mdPlugins":[],"hastPlugins":[],"ignore":["README.md"],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/home/runner/work/css/css/src/docs/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":"../../docs","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"CSS - Components Squid Style","description":"Front-end framework style created by Squid","host":"localhost","port":3535,"p":3000,"separator":"-","paths":{"root":"/home/runner/work/css/css/src/docs","templates":"/home/runner/work/css/css/src/docs/node_modules/docz-core/dist/templates","docz":"/home/runner/work/css/css/src/docs/.docz","cache":"/home/runner/work/css/css/src/docs/.docz/.cache","app":"/home/runner/work/css/css/src/docs/.docz/app","appPackageJson":"/home/runner/work/css/css/src/docs/package.json","appTsConfig":"/home/runner/work/css/css/src/docs/tsconfig.json","gatsbyConfig":"/home/runner/work/css/css/src/docs/gatsby-config.js","gatsbyBrowser":"/home/runner/work/css/css/src/docs/gatsby-browser.js","gatsbyNode":"/home/runner/work/css/css/src/docs/gatsby-node.js","gatsbySSR":"/home/runner/work/css/css/src/docs/gatsby-ssr.js","importsJs":"/home/runner/work/css/css/src/docs/.docz/app/imports.js","rootJs":"/home/runner/work/css/css/src/docs/.docz/app/root.jsx","indexJs":"/home/runner/work/css/css/src/docs/.docz/app/index.jsx","indexHtml":"/home/runner/work/css/css/src/docs/.docz/app/index.html","db":"/home/runner/work/css/css/src/docs/.docz/app/db.json"},"repository":"https://github.com/squidit/css"},
    }]
